<template>
    <div class="power-player-list">


        <el-dialog
                title="EDIT VIDEO"
                center
                :width="windowWidth > 800 ? `40%` : `95%`"
                class="el-dialog--huge dialog_edit dialog_border_radius"
                :visible.sync="editPlayerDilogue"
                :modalAppendToBody="false"
        >
            <div class="relative mb-3 ">
                <el-input v-model="videoToEdit.title" placeholder="VIDEO TITLE"></el-input>
                <span v-if="validateVideo.title">{{validateVideo.message}}</span>

                <div style="margin-top: 30px;">
                    <el-input placeholder="Please input" v-model="videoToEdit.path" class="input-with-select">
                        <el-select v-model="videoToEdit.power_player_type" slot="prepend"
                                   :placeholder="videoToEdit.power_player_type" :style="{'width': 150+'px'}">
                            <el-option label="YOUTUBE" value="YOUTUBE"></el-option>
                            <el-option label="MP4" value="MP4/WEBM"></el-option>
                            <el-option label="WISTIA" value="WISTIA"></el-option>
                            <el-option label="VIMEO" value="VIMEO"></el-option>
                            <el-option label="M3U8" value="M3U8/MPD"></el-option>
                            <el-option label="LIVE" value="LIVE STREAMING"></el-option>
                        </el-select>
                        <!--                                        <el-button slot="append" icon="el-icon-search"></el-button>-->
                    </el-input>
                    <span v-if="validateVideo.path">{{validateVideo.message}}</span>
                </div>
                <el-col class="row-space-3 mt-2">
                    <el-checkbox v-model="videoToEdit.youtube_control">
                        <span class="text-lightest" style="color: #21455E">Display YouTube logo for compliance</span>
                    </el-checkbox>
                </el-col>

            </div>

            <div slot="footer" class="dialog-footer">
                <el-button class="btn btn-primary add-power-player-button" @click="powerPlayerVideoEdit">UPDATE
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
                title="ARE YOU SURE?"
                center
                :width="windowWidth > 1024 ? `40%` : '95%'"
                class="el-dialog--huge dialog_border_radius"
                :visible.sync="showDeletePopup"
                :modalAppendToBody="false"
        >
            <div class="el-dialog__body" :style='{"padding": windowWidth <= 1024 ? "0px !important" : ""}'>
                <div style="display:flex;flex-direction:column">
          <span style="margin-top:10px">
            You are about to Delete this Video.Deleted videos are removed from your account
            and they will become unavailable wherever they are embeded
          </span>
                </div>
                <div class="col-md-12" style="margin-top:20px">
                    <div class="col-md-4" style="display:flex;flex-direction:column">
                        <label>VIDEO</label>
                        <div style="word-break: break-word">{{videoToEdit.title}}</div>
                    </div>
                    <div class="col-md-4" style="display:flex;flex-direction:column">
                        <label>IMPRESSIONS</label>
                        <span>{{videoToEdit.impressions}}</span>
                    </div>
                    <div class="col-md-4" style="display:flex;flex-direction:column">
                        <label>VIEWS</label>
                        <span>{{videoToEdit.views}}</span>
                    </div>
                </div>
                <div style="margin-top:100px">
                    <label>
                        Type
                        <b style="color:red">DELETE</b> to confirm
                    </label>
                    <div class="row-space-top-1 el-input">
                        <input
                                type="text"
                                autocomplete="off"
                                v-model="typeConfirmDlete"
                                class="el-input__inner custom_input_new_style"
                        />
                    </div>

                    <span style="color:red" v-if="isTypedDeleteError">
            You must type
            <b style="color:red">DELETE</b> to confirm
          </span>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
        <el-button @click="showDeletePopup = false" :loading="loadingDelete" type="gray" round>Cancel</el-button>
        <el-button type="danger" :loading="loadingDelete" @click="deleteVideoConfirm" round>Delete</el-button>
      </span>
        </el-dialog>


        <el-dialog
                title="DUPLICATE VIDEO"
                center
                :width="windowWidth > 1024 ? `40%` : '95%'"
                class="el-dialog--huge dialog_border_radius duplicate-power-player"
                :visible.sync="showDuplicatePopup"
                :modalAppendToBody="false"
        >
            <div :style='{"padding": windowWidth <= 1024 ? "0px !important" : ""}'>
                <div style="display:flex;flex-direction:column">
                    <span style="margin-top:10px">Create a duplicate copy of this power player</span>
                </div>
                <div style="margin-top:20px">
                    <label>Title</label>
                    <div class="row-space-top-1 el-input">
                        <input
                                type="text"
                                autocomplete="off"
                                v-model="duplicateVideoTitle"
                                class="el-input__inner custom_input_new_style"
                        />
                    </div>

                    <span style="color:red" v-if="duplicateVideoTitle==''">This field is required</span>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
        <el-button @click="showDuplicatePopup = false" type="gray">Cancel</el-button>
        <el-button type="primary" @click="duplicateVideo">Duplicate</el-button>
      </span>
        </el-dialog>


        <div class="player_video__body" v-for="(video, index) in powerVideos">

            <div class="list-power-video">
                <div class="power-player-id inner-div" @click="redirectTo(video.video_id)">
                    <span :style="{'background-color': backgroundColor(video.power_player_type)}">#{{index + 1}}</span>
                </div>
                <div class="power-player-detail inner-div" @click="redirectTo(video.video_id)">
                    <div class="item__title">{{video.title}}</div>
                    <div class="item__path"> {{video.path}}</div>
                </div>
                <div class="power-player-impression inner-div" @click="redirectTo(video.video_id)">
                    <div>
                        <div class="item__title">{{video.impressions}}</div>
                        <div class="item__path"> Impresions</div>
                        <!--                        <span>{{video.impressions}}</span><br>-->
                        <!--                        <span class="tag_title">Impresions</span>-->
                    </div>
                </div>
                <div class="power-player-views inner-div" @click="redirectTo(video.video_id)">
                    <div class="item__title">{{video.views}}</div>
                    <div class="item__path"> Views</div>
                    <!--                    <span> {{video.views}}</span><br>-->
                    <!--                    <span class="tag_title">Views</span>-->
                </div>
                <div class="power-player-actions inner-div">
                    <div>
                        <el-dropdown class="m-left-2" trigger="click" :placement="windowWidth > 550 ? 'bottom' : 'left'" @command="handleCommand" @visible-change="videoble($event, video)">
                            <div class="el-dropdown-link btn btn-circle">
                                <i class="el-icon-more"></i>
                            </div>

                            <el-dropdown-menu slot="dropdown" class="custom_dropdown dropdown_bottom_200">
                                <el-dropdown-item command="share">Share & Embed</el-dropdown-item>
                                <el-dropdown-item command="copy-link">Copy Player Link</el-dropdown-item>
                                <el-dropdown-item command="edit" divided>Edit Title & Source</el-dropdown-item>
<!--                                <el-dropdown-item command="analytics">Video Analytics</el-dropdown-item>-->
                                <el-dropdown-item command="duplicate" divided>Duplicate</el-dropdown-item>
                                <el-dropdown-item command="delete" class="delete" style="color:red">Delete
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>

        </div>


        <embed-modal ref="shareModal" :video="videoToEdit" v-if='embedModal'
                     @trigger='embedModal = false'></embed-modal>

    </div>
</template>

<script>

    import EmbedModal
        from '../../../components/Project/ChildPages/ProjectExperimentationPages/CommonComponents/EmbedModal';


    export default {
        name: 'List',
        props: ['powerVideos'],
        components: {EmbedModal},
        data: function () {
            return {

                videoToEdit: {
                    title: '',
                    power_player_type: 'YOUTUBE',
                    path: '',
                    youtube_control:true,
                },
                editPlayerDilogue: false,
                showDeletePopup: false,
                validateVideo: {
                    title: false,
                    power_player_type: false,
                    path: false,
                    message: ''
                },
                typeConfirmDlete: "",
                loadingDelete: false,
                isTypedDeleteError: false,
                showDuplicatePopup: false,
                duplicateVideoTitle: '',
                windowWidth: null,
                embedModal: false,


            }
        },
        created: function () {
        },
        mounted: function () {
            window.addEventListener('resize', this.onResize);
            this.onResize();

        },
        methods: {
            redirectTo(video_id) {
                this.$router.push('/power-player/edit/' + video_id);
            },
            backgroundColor(type) {
                var color = ''
                if (type == 'YOUTUBE') {
                    color = '#af0a0a';
                } else if (type == 'VIMEO') {
                    color = '#40b6da';
                } else if (type == 'WISTIA') {
                    color = 'rgb(10 68 177)';
                } else if (type == 'MP4') {
                    color = '#25a95af0';
                } else if (type == 'M3U8') {
                    color = '#a92574';
                } else if (type == 'LIVE') {
                    color = '#e8c207';
                }
                return color

            },
            onResize() {
                this.windowWidth = window.innerWidth;
                if (this.windowWidth <= 1024) return false;
                return true;
            },
            videoble(event, video) {
                if (event) {
                    this.videoToEdit = video
                }
            },
            handleCommand(command) {

                if (command == "copy-link") {
                    console.log(this.videoToEdit);
                    var el = document.createElement("textarea");
                    el.value = this.videoToEdit.embed_url;
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand("copy");
                    document.body.removeChild(el);
                }

                if (command == "share") {
                    this.embedModal = true;
                }

                if (command == "edit") {
                    this.editPlayerDilogue = true;
                }
                if (command == "delete") {
                    this.showDeletePopup = true;
                }
                if (command == "analytics") {
                    window.location.href = "/analytics?video=" + this.videoToEdit.id;
                }
                if (command == "duplicate") {
                    this.showDuplicatePopup = true;
                    this.duplicateVideoTitle = this.videoToEdit.title + " 2";
                }

            },
            powerPlayerVideoEdit() {
                this.validateVideo.title = false;
                this.validateVideo.message = '';
                this.validateVideo.path = false;

                if (this.videoToEdit.title == '') {
                    this.validateVideo.title = true;
                    this.validateVideo.message = 'Please enter video title';
                    return false;
                } else {
                    this.validateVideo.title = false;
                    this.validateVideo.message = '';
                }
                if (this.videoToEdit.path == '') {
                    this.validateVideo.path = true;
                    this.validateVideo.message = 'Please enter source URL';
                    return false;
                } else {


                    if (this.videoToEdit.power_player_type == 'YOUTUBE') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.videoToEdit.path.includes('www.youtube.com')) {
                            return false;
                        }
                    }
                    if (this.videoToEdit.power_player_type == 'WISTIA') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.videoToEdit.path.includes('wistia.com')) {
                            return false;
                        }
                    }
                    if (this.videoToEdit.power_player_type == 'VIMEO') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.videoToEdit.path.includes('vimeo.com')) {
                            return false;
                        }
                    }

                    if (this.videoToEdit.power_player_type == 'MP4') {

                        var re = /(?:\.([^.]+))?$/;

                        var ext = re.exec(this.videoToEdit.path)[1];

                        if (typeof ext == 'undefined' || ext !== "mp4") {
                            this.validateVideo.path = true;
                            this.validateVideo.message = 'Please enter valid source URL';
                            if (!this.videoToEdit.path.includes('vimeo.com')) {
                                return false;
                            }
                        }
                    }
                    if (this.videoToEdit.power_player_type == 'M3U8') {

                        var re = /(?:\.([^.]+))?$/;

                        var ext = re.exec(this.videoToEdit.path)[1];

                        if (typeof ext == 'undefined' || ext !== "m3u8") {
                            this.validateVideo.path = true;
                            this.validateVideo.message = 'Please enter valid source URL';
                            if (!this.videoToEdit.path.includes('vimeo.com')) {
                                return false;
                            }
                        }
                    }


                    this.validateVideo.path = false;
                    this.validateVideo.message = '';
                }

                this.axios.post(`/powerPlayerVideos/edit/${this.videoToEdit.id}`, this.videoToEdit)
                    .then(response => {

                        this.editPlayerDilogue = false;
                        this.$emit('getPowerVideos');


                    });
            },
            deleteVideoConfirm: function () {
                if (this.typeConfirmDlete == "" || this.typeConfirmDlete != "DELETE") {
                    this.isTypedDeleteError = true;
                } else {
                    this.isTypedDeleteError = false;
                    this.loadingDelete = true;
                    let $this = this;

                    this.axios({
                        method: "post",
                        url: `powerPlayerVideos/delete/${this.videoToEdit.id}`,

                    })
                        .then(response => {
                            this.loadingDelete = false;
                            this.typeConfirmDlete= ''
                            this.showDeletePopup = false;
                            this.$emit('getPowerVideos');
                            // if (
                            //     typeof response.data.success !== typeof undefined &&
                            //     response.data.success === true
                            // ) {
                            //     $this.$router.push({
                            //         name: "ProjectView",
                            //         params: {
                            //             id: $this.video.project.project_id
                            //         }
                            //     });
                            // }
                        })
                        ["catch"](error => {
                    });
                }
            },
            duplicateVideo() {

                var data = {
                    video_id: this.videoToEdit.video_id,
                    video_title: this.duplicateVideoTitle,
                }
                this.axios.post(`/powerPlayerVideos/duplicate`, data)
                    .then(response => {

                        this.showDuplicatePopup = false;
                        this.$emit('getPowerVideos');


                    });
            },
        },
    }
</script>

<style lang="less">
    .duplicate-power-player{
        .el-dialog__body{}
    }

    .el-dialog--center .el-dialog__header {
        padding: 25px 40px;
        text-align: center;
        border-bottom: 1px solid #e4e8f1;
    }

    .el-dialog__title {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: normal;
        font-size: 15px;
    }

    .power-player-list {

        .tag_title {
            font-size: 15px;
        }

        .content__col {
            flex-grow: 1;
            flex-shrink: 1;
            min-width: 0;
            padding: 0 8px;

            &.content__col--image {
                position: relative;
                flex-grow: 0;
                flex-basis: 136px;
                flex-shrink: 0;

                img {
                    width: 120px;
                    height: 67px;
                    object-fit: cover;
                }
            }

            &.content__col--title {
                flex-basis: 400px;
                min-width: 150px;
            }

            &.content__col--view {
                flex-basis: 100px;
                min-width: 100px;
            }

            &.content__col--comment {
                flex-basis: 150px;
                min-width: 150px;
            }

            &.content__col--size {
                flex-basis: 100px;
                min-width: 100px;
            }

            &.content__col--last-modified {
                flex-basis: 150px;
                min-width: 150px;
            }
        }

        .player_video__body {
            background-color: white;
            /* padding: 30p x 30px 30px; */
            box-shadow: 0px 2px 6px 0px #00000026;
            border-radius: 5px;
            margin-bottom: 30px;
            height: 100px;
            cursor: pointer;
            @media(max-width:520px){
                height: 80px;
            }

            .list-power-video {
                display: flex;

                .inner-div {
                    flex-wrap: wrap;

                }

                .power-player-id, .power-player-actions {
                    flex: 0 0 10%;
                }

                .power-player-actions {
                    line-height: 70px;
                    text-align: center;
                    .btn-circle {
                        border: 0;
                        background-color: transparent;
                        font-size: 16px;
                        border-radius: 30px;
                        color: #0BACDB;
                        padding: 8px 11px !important;
                        border: 1px solid #0BACDB !important;


                        &:hover {
                            background-color: #F7FCFD;
                        }
                    }

                    .el-dropdown-link {
                        color: #0BACDB;
                    }
                }

                .power-player-detail {
                    flex: 0 0 50%;
                    padding: 25px 15px;
                    @media(max-width:650px){
                        flex: 0 0 40%;
                    }
                    @media(max-width:510px){
                        flex: inherit;
                        padding: 15px 5px 15px 10px;
                    }
                    @media(max-width: 450px){
                        flex-grow: 1;
                    }

                    .item__title {
                        font-size: 14px;
                        margin-bottom: 9px;
                        line-height: 27px;
                        font-weight: 500;
                        font-size: 22px;
                        color: #2e455ebd;
                        font-weight: 500;
                        display: inline-block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 350px;
                        display: block;


                        &:hover {
                        }
                    }

                    .item__path {
                        font-size: 13px;
                        color: #2c96b6;
                        font-style: italic;
                        max-width: 350px;
                        display: inline-block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        @media(max-width:450px){
                            max-width: 160px !important;
                        }
                        @media(max-width:400px){
                            max-width: 130px !important;
                        }
                        @media(max-width:359px){
                            max-width: 100px !important;
                        }

                    }
                }

                .power-player-impression, .power-player-views {
                    flex: 0 0 15%;
                    padding: 25px 15px;
                    /*line-height: 100px;*/
                    /*font-size: 22px;*/
                    text-align: center;
                    @media(max-width:650px){
                        flex: 0 0 17%;
                    }
                    @media(max-width:570px){
                        flex: 0 0 12%;
                        padding: 25px 3px;
                    }
                    @media(max-width:520px){
                        flex: 0 0 12%;
                        padding: 15px 3px;
                    }

                    .item__title {
                        font-size: 15px;
                        margin-bottom: 9px;
                        line-height: 27px;
                        font-weight: 500;
                        font-size: 22px;
                        color: #000000bd;
                        font-weight: 500;


                        &:hover {
                        }
                    }

                    .item__path {
                        font-size: 13px;
                        /*color: #2c96b6;*/
                        /*font-style: italic;*/
                        max-width: 350px;
                        display: inline-block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;

                    }
                }

                /*.power-player-views {*/
                /*    flex: 0 0 15%;*/
                /*    line-height: 100px;*/
                /*    font-size: 22px;*/
                /*    text-align: center;*/
                /*}*/

                .power-player-id {
                    span {
                        text-align: center;
                        display: block;
                        /*height: 100%;*/
                        font-size: 22px;
                        line-height: 100px;
                        font-weight: bold;
                        color: #fff;
                        background-color: #af0a0a;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        @media(max-width:510px){
                            line-height: 80px;
                        }
                    }
                }
            }
        }

        .el-dropdown{
            margin-top: 15px;
            @media(max-width:520px){
                margin-top:5px;
                margin-right: 10px;
            }
        }
        .el-dropdown-link{
            font-size: 10px !important;
        }
        /*<!--.el-dropdown-menu:before {-->*/
        /*<!--    content: '';-->*/
        /*<!--    display: block;-->*/
        /*<!--    position: absolute;-->*/
        /*<!--    width: 14px;-->*/
        /*<!--    height: 14px;-->*/
        /*<!--    box-sizing: border-box;-->*/
        /*<!--    transform: rotate(45deg);-->*/
        /*<!--    border: 1px solid var(&#45;&#45;color-blue);-->*/
        /*<!--    background-color: white;-->*/
        /*<!--    border-radius: 3px;-->*/
        /*<!--}-->*/

        /*<!--.el-dropdown-link {-->*/
        /*<!--    text-align: center;-->*/
        /*<!--    width: 40px;-->*/
        /*<!--    height: 40px;-->*/
        /*<!--    border: 2px solid #469edb;-->*/
        /*<!--    border-radius: 100px;-->*/
        /*<!--    background: url(../../../assets/img/three_points_h.svg) center center no-repeat, url(../../../assets/img/three_points_h.svg) -9999px no-repeat !important;-->*/
        /*<!--    background-size: 15px !important;-->*/
        /*<!--    display: inline-block;-->*/
        /*<!--    margin-top: 30px;-->*/
        /*<!--    cursor: pointer;-->*/
        /*<!--}-->*/

        .custom_dropdown.dropdown_bottom_200 {
            width: 200px !important;
        }

        .custom_dropdown.dropdown_bottom_200:before {
            top: -7px;
            left: 93px;
            border-bottom-color: transparent;
            border-right-color: transparent;
        }

        .custom_dropdown:before {
            content: "";
            display: block;
            position: absolute;
            width: 14px;
            height: 14px;
            box-sizing: border-box;
            transform: rotate(45deg);
            border: 1px solid var(--color-blue);
            background-color: #fff;
            border-radius: 3px;
        }

        .custom_dropdown {
            list-style: none;
            border-radius: 6px;
            background: #fff;
            border: 1px solid #0bacdb;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
            margin-top: 0 !important;
            padding: 5px 0;
        }

        .title {
            display: flex;
            align-items: flex-end;

            span {
                font-weight: 700;
                font-size: 24px;
                letter-spacing: 1px;
                margin-bottom: 0;
                border: 1px solid transparent;
                padding: 10px 0 0 10px;
                margin-left: -5px;
                padding-left: 5px;
                border-radius: 4px;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                line-height: 1.2em;
                color: var(--color-content);
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis !important;
            }

            .editIcon {
                cursor: pointer;
                padding-left: 15px;
            }

            // &:hover{
            //     border: 1px solid #DBE3E6;
            // }
        }

        /*input {*/
        /*    font-size: 24px;*/
        /*    font-weight: 700;*/
        /*    padding: 10px 5px !important;*/
        /*    margin: 0;*/
        /*    letter-spacing: 1px;*/
        /*    margin-left: -5px;*/
        /*    height: 50px !important;*/
        /*}*/

        .dropdown-container {
        }

        .meta-info {
            font-size: 0.9em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
    }

</style>
